import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

export const FormulariosEvaluacionesClinico = ({contenidoInvestigacion, userLogin, updateEvalucionMetodologico}) => {
    const [item, setItem] = useState({relevanciaClinica:'0'})
    const [totalPuntos, setTotalPuntos] = useState(0)
    const [validated, setValidated] = useState(false);

    const users = contenidoInvestigacion.users.sort(function (a, b) {
        if (a.presentador) {
          return -1;
        }
        if (b.presentador) {
          return 1;
        }
        // a must be equal to b
        return 0;
    });
    useEffect(()=>{
        let dataEvaluacion = contenidoInvestigacion.evaluaciones?.find((item) => item.id === userLogin.id && item.tipoEvaluacion === "clinico")
        
        if(dataEvaluacion !== undefined){
            setItem({...dataEvaluacion.evaluacion.itemsEvaluados})
        }
    },[])
    useEffect(()=>{
        const totalPuntos = Object.values(item).reduce((a, b) => parseInt(a) + parseInt(b), 0);
        setTotalPuntos(totalPuntos)

    },[item])
    const changeField = (field, value, attribute) => {
        let data = value
        if(value >= parseInt(attribute.max)){
            data = parseInt(attribute.max)
        }
        else if(value === ''){
            data = 0
        }
        setItem({
          ...item,
          [field]:  data
        })
        if(!!validated[field]){
          setValidated({
            ...validated,
            [field]:null
          })
        }
    }
    const validateForm = () => {
        const { relevanciaClinica } = item
        const newErros = {}
        
        if(!relevanciaClinica            || relevanciaClinica === ''          || relevanciaClinica === undefined) newErros.relevanciaClinica = 'Relevancia clinica obligatorio'

        return newErros
    }
    const handleSubmitEvaluacion = (e) => {
        e.preventDefault()
        const formErrors = validateForm();
        if(Object.keys(formErrors).length > 0){
            setValidated(formErrors)
        }
        else{
            updateEvalucionMetodologico(userLogin, item, totalPuntos)
        }
    }
    return (
        <Container fluid className='p-0 pb-4'>
            <Row className='m-0'>
                <Col xs={9} className='p-0'>
                    <div className='contenido pt-4'>
                        <Container className='m-0 p-0'>
                            <Row className='m-0'>
                                <Col xs={12} className='p-0'>
                                    <p><strong>Título:</strong><br />{contenidoInvestigacion.titulo}</p>
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                {/*users.length !== 0 &&
                                    <Col xs={6} className='p-0'>
                                        <p><strong>Investigadores:</strong><br />{users.map((user)=>{
                                            return <span key={user.id}>{user.nombre} {user.apellido}{user.presentador && ' (presentador)'}<br/></span>
                                        })}</p>
                                    </Col>
                                */}
                                <Col xs={6} className='p-0'>
                                    <p><strong>Evaluador:</strong><br />{userLogin.nombre.toUpperCase()} {userLogin.apellido.toUpperCase()}</p>
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col xs={12} className='p-0'>
                                    <hr />
                                    <h3>FORMATO DE EVALUACIÓN</h3>
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col xs={12} className='p-0'>
                                    <Form onSubmit={handleSubmitEvaluacion} className="mb-3">
                                        <Row>
                                            <Col xs={10}>
                                                <p><strong>Relevancia clínica (0 a 10 puntos)</strong>
                                                <br />Resultados potencialmente aplicables a la práctica por la importancia del efecto observado.
                                                <br />Los desenlaces del estudio son centrados en el paciente, es decir clínicamente relevantes y no subrogados.
                                                <br />El impacto del estudio va más allá del corto plazo.
                                                <br />Ayuda en el proceso de toma de decisiones.
                                                </p>
                                            </Col>
                                            <Col xs={2} className='d-flex justify-content-end align-items-center'>
                                                <Form.Control type='number' placeholder='0' min='0' max='10' style={{width:"70px"}} className='text-center' name='relevanciaClinica' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.relevanciaClinica === undefined ? 0 : item.relevanciaClinica} isInvalid={!!validated.relevanciaClinica}/>
                                                
                                            </Col>
                                        </Row>
                                        
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
                <Col xs={3} className=''>
                    <div className='puntuacion'>
                        <div className='total'>
                            <p className="puntaje">{totalPuntos}</p>
                            <p className="titlePuntos">Puntos Evaluados</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={9} className='d-flex justify-content-end'>
                    <Button onClick={handleSubmitEvaluacion} className="btn-custom"><p>Evaluar <FontAwesomeIcon icon={faSave} /></p></Button>
                </Col>
            </Row>
        </Container>
    )
}

