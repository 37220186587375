import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

export const FormulariosEvaluacionesEvento = ({contenidoInvestigacion, userLogin, updateEvalucionMetodologico}) => {
    const [item, setItem] = useState({manejoTiempo:'0', disenoEstructura:'0', integridadContenido:'0', abordaje:'0', preguntasRespuestaOral:'0', disenoPoster:'0', poster:'0', discurso:'0',  preguntasRespuestaPoster:'0' })
    const [totalPuntos, setTotalPuntos] = useState(0)
    const [validated, setValidated] = useState(false);

    const users = contenidoInvestigacion.users.sort(function (a, b) {
        if (a.presentador) {
          return -1;
        }
        if (b.presentador) {
          return 1;
        }
        // a must be equal to b
        return 0;
    });
    useEffect(()=>{
        let dataEvaluacion = contenidoInvestigacion.evaluaciones?.find((item) => item.id === userLogin.id && item.tipoEvaluacion === "evento")
        if(dataEvaluacion !== undefined){
            setItem({...dataEvaluacion.evaluacion.itemsEvaluados})
        }
    },[])
    useEffect(()=>{
        const totalItems = {...item}
        if(totalItems.tipoDeInvestigacion     === "PRESENTACION ORAL"){
            delete totalItems.disenoPoster
            delete totalItems.poster
            delete totalItems.discurso
            delete totalItems.preguntasRespuestaPoster
            
        }
        else if(totalItems.tipoDeInvestigacion === "PRESENTACION POSTER"){
            delete totalItems.manejoTiempo
            delete totalItems.disenoEstructura 
            delete totalItems.integridadContenido
            delete totalItems.abordaje
            delete totalItems.preguntasRespuestaOral
        }

        delete totalItems.tipoDeInvestigacion
        const totalPuntos = Object.values(totalItems).reduce((a, b) => parseInt(a) + parseInt(b), 0);
        setTotalPuntos(totalPuntos)
        
    },[item])
    const changeField = (field, value, attribute) => {
        
        let data = value
        if(value >= parseInt(attribute.max)){
            data = parseInt(attribute.max)
        }
        else if(value === ''){
            data = 0
        }
        setItem({
          ...item,
          [field]:  data
        })

        
        if(!!validated[field]){
          setValidated({
            ...validated,
            [field]:null
          })
        }
    }
    const validateForm = () => {
        const { tipoDeInvestigacion, manejoTiempo, disenoEstructura, integridadContenido, abordaje, preguntasRespuestaOral, disenoPoster, poster } = item
        const newErros = {}
        if(!tipoDeInvestigacion         || tipoDeInvestigacion === ''           || tipoDeInvestigacion === undefined)           newErros.tipoDeInvestigacion = 'Se debe seleecionar un tipo de calificacion'
        
        if(tipoDeInvestigacion     === "PRESENTACION ORAL"){
            if(!manejoTiempo                || manejoTiempo === ''                  || manejoTiempo === undefined)                  newErros.manejoTiempo = 'Manejo de tiempo obligatorio'
            if(!disenoEstructura            || disenoEstructura === ''              || disenoEstructura === undefined)              newErros.disenoEstructura = 'Diseño y estructura de la presentación obligatorio'
            if(!integridadContenido         || integridadContenido === ''           || integridadContenido === undefined)           newErros.integridadContenido = 'Integridad del contenido obligatorio'
            if(!abordaje                    || abordaje === ''                      || abordaje === undefined)                      newErros.abordaje = 'Abordaje del tema obligatorio'
            if(!preguntasRespuestaOral      || preguntasRespuestaOral === ''        || preguntasRespuestaOral === undefined)        newErros.preguntasRespuestaOral = 'Respuesta a preguntas obligatorio'
        }
        else if(tipoDeInvestigacion     === "PRESENTACION POSTER"){
            if(!disenoPoster                || disenoPoster === ''                  || disenoPoster === undefined)                  newErros.disenoPoster = 'Diseño del póster obligatorio'
            if(!poster                      || poster === ''                        || poster === undefined)                        newErros.poster = 'Información póster obligatorio'
            
        }

        return newErros
    }
    const handleSubmitEvaluacion = (e) => {
        e.preventDefault()
        const formErrors = validateForm();
        if(Object.keys(formErrors).length > 0){
            setValidated(formErrors)
        }
        else{
            const totalItems = {...item}
            if(totalItems.tipoDeInvestigacion     === "PRESENTACION ORAL"){
                delete totalItems.disenoPoster
                delete totalItems.poster
                delete totalItems.discurso
                delete totalItems.preguntasRespuestaPoster
                
            }
            else if(totalItems.tipoDeInvestigacion === "PRESENTACION POSTER"){
                delete totalItems.manejoTiempo
                delete totalItems.disenoEstructura 
                delete totalItems.integridadContenido
                delete totalItems.abordaje
                delete totalItems.preguntasRespuestaOral
            }
            console.log("totalItems", totalItems)
            updateEvalucionMetodologico(userLogin, totalItems, totalPuntos)
        }
    }
    console.log("item", item)
    return (
        <Container fluid className='p-0 pb-4'>
            <Row className='m-0'>
                <Col xs={9} className='p-0'>
                    <div className='contenido pt-4'>
                        <Container className='m-0 p-0'>
                            <Row className='m-0'>
                                <Col xs={12} className='p-0'>
                                    <p><strong>Título:</strong><br />{contenidoInvestigacion.titulo}</p>
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                {/*users.length !== 0 &&
                                    <Col xs={6} className='p-0'>
                                        <p><strong>Investigadores:</strong><br />{users.map((user)=>{
                                            return <span key={user.id}>{user.nombre} {user.apellido}{user.presentador && ' (presentador)'}<br/></span>
                                        })}</p>
                                    </Col>
                                */}
                                <Col xs={6} className='p-0'>
                                    <p><strong>Evaluador:</strong><br />{userLogin.nombre.toUpperCase()} {userLogin.apellido.toUpperCase()}</p>
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col xs={12} className='p-0'>
                                    <hr />
                                    <h3>FORMATO DE EVALUACIÓN</h3>
                                </Col>
                            </Row>
                            <Row className='m-0'>
                                <Col xs={12} className='p-0'>
                                    <Form onSubmit={handleSubmitEvaluacion} className="mb-3">
                                        <Row className='mb-4'>
                                            <Col xs={12}>
                                                <Form.Check inline label="PRESENTACIÓN ORAL"    name='tipoDeInvestigacion' type='radio' checked={item.tipoDeInvestigacion === "PRESENTACION ORAL"   && "checked"} onChange={(e) => {changeField(e.target.name, 'PRESENTACION ORAL', e.target)} }         isInvalid={!!validated.tipoDeInvestigacion}/>
                                                <Form.Check inline label="PRESENTACIÓN POSTER"  name='tipoDeInvestigacion' type='radio' checked={item.tipoDeInvestigacion === "PRESENTACION POSTER" && "checked"} onChange={(e) => {changeField(e.target.name, 'PRESENTACION POSTER', e.target)} }     isInvalid={!!validated.tipoDeInvestigacion}/>
                                            </Col>
                                        </Row>

                                        {
                                            item.tipoDeInvestigacion === "PRESENTACION ORAL" &&
                                            <>
                                                <Row>
                                                    <Col xs={10}>
                                                        <p><strong>Manejo de tiempo, espacios, de la voz, del cuerpo y presentación personal (0 a 10 puntos)</strong></p>
                                                    </Col>
                                                    <Col xs={2} className='d-flex justify-content-end align-items-center' >
                                                        <Form.Control type='number' placeholder='0' min='0' max='10' style={{width:"70px"}} className='text-center' name='manejoTiempo' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.manejoTiempo === undefined ? 0 : item.manejoTiempo} isInvalid={!!validated.manejoTiempo}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={10}>
                                                        <p><strong>Diseño y estructura de la presentación (0 a 10 puntos)</strong>
                                                        <br />Estética y armonía</p>
                                                    </Col>
                                                    <Col xs={2} className='d-flex justify-content-end align-items-center'>
                                                        <Form.Control type='number' placeholder='0' min='0' max='10' style={{width:"70px"}} className='text-center' name='disenoEstructura' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.disenoEstructura === undefined ? 0 : item.disenoEstructura} isInvalid={!!validated.disenoEstructura}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={10}>
                                                        <p><strong>Integridad del contenido  (0 a 30 puntos)</strong>
                                                        <br />Título y nombre de investigadores, Antecedentes, Objetivo, Metodología, Resultados, Limitaciones, Conclusiones e implicaciones</p>
                                                    </Col>
                                                    <Col xs={2} className='d-flex justify-content-end align-items-center'>
                                                        <Form.Control type='number' placeholder='0' min='0' max='30' style={{width:"70px"}} className='text-center' name='integridadContenido' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.integridadContenido === undefined ? 0 : item.integridadContenido} isInvalid={!!validated.integridadContenido}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={10}>
                                                        <p><strong>Abordaje del tema y claridad en la presentación  (0 a 20 puntos)</strong></p>
                                                    </Col>
                                                    <Col xs={2} className='d-flex justify-content-end align-items-center'>
                                                        <Form.Control type='number' placeholder='0' min='0' max='20' style={{width:"70px"}} className='text-center' name='abordaje' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.abordaje === undefined ? 0 : item.abordaje} isInvalid={!!validated.abordaje}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={10}>
                                                        <p><strong>Respuesta a preguntas y solidez en la defensa  (0 a 30 puntos)</strong>
                                                        <br />Orientadas a la aplicación en el área de cuidados intensivos</p>
                                                    </Col>
                                                    <Col xs={2} className='d-flex justify-content-end align-items-center'>
                                                        <Form.Control type='number' placeholder='0' min='0' max='30' style={{width:"70px"}} className='text-center' name='preguntasRespuestaOral' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.preguntasRespuestaOral === undefined ? 0 : item.preguntasRespuestaOral} isInvalid={!!validated.preguntasRespuestaOral}/>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {
                                            item.tipoDeInvestigacion === "PRESENTACION POSTER" &&
                                            <>
                                                <Row>
                                                    <Col xs={11}>
                                                        <p><strong>Diseño del póster  (0 a 20 puntos)</strong>
                                                        <br />Presentación gráfica, estética y armonía</p>
                                                    </Col>
                                                    <Col xs={1}>
                                                        <Form.Control type='number' placeholder='0' min='0' max='20' className='text-center' name='disenoPoster' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.disenoPoster === undefined ? 0 : item.disenoPoster} isInvalid={!!validated.disenoPoster}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={11}>
                                                        <p><strong>El póster tiene información coherente con el resumen sometido:  (0 a 80 puntos)</strong>
                                                        <br />Problema claramente definido, Objetivo de la investigación, Metodología clara y concisa, Resultados focalizados y fácilmente interpretables, Incluye las principales limitaciones, Conclusiones acordes al estudio, Referencias pertinentes</p>
                                                    </Col>
                                                    <Col xs={1}>
                                                        <Form.Control type='number' placeholder='0' min='0' max='80' className='text-center' name='poster' required onChange={(e) => {changeField(e.target.name, e.target.value, e.target)} } value={item.poster === undefined ? 0 : item.poster} isInvalid={!!validated.poster}/>
                                                    </Col>
                                                </Row>
                                                
                                            </>
                                        }



                                        <Row>
                                            <Col xs={2} className='d-flex justify-content-end align-items-center'>

                                                

                                                
                                            </Col>
                                        </Row>
                                        
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
                <Col xs={3} className=''>
                    <div className='puntuacion'>
                        <div className='total'>
                            <p className="puntaje">{totalPuntos}</p>
                            <p className="titlePuntos">Puntos Evaluados</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={9} className='d-flex justify-content-start'>
                    <Button onClick={handleSubmitEvaluacion} className="btn-custom"><p>Evaluar <FontAwesomeIcon icon={faSave} /></p></Button>
                </Col>
            </Row>
        </Container>
    )
}

