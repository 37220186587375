import React from 'react'
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ContentNull } from '../../ContentNull/ContentNull'
import { Loading } from '../../Loading/Loading'
import { Tooltip } from 'react-tooltip'

export const ListInvestigaciones = ({contentInvestigaciones, handleViewInvestigation, userLogin, contentCheckBox, setContentCheckBox}) => {
    const [isChecked, setIsChecked] = useState({})
    


    
    const handleOnChange = (e) => {
        const value = e.target.value
        if(isChecked[value] === undefined || isChecked[value] === false){
            setIsChecked({...isChecked,
                [value]:true    
            })
            setContentCheckBox([...contentCheckBox, value])
        }
        else{
            setIsChecked({...isChecked,
                [value]:false    
            })
            let response =  []
            contentCheckBox.forEach((item) => {
                if(item !== value){
                    response.push(item) 
                }
            })
            setContentCheckBox([...response])
        }
    }
    if(contentInvestigaciones === null){
        return (<ContentNull texto='No hay investigaciones' />)
    }
    else if(contentInvestigaciones.length === 0) {
        return (<Loading/>)
    }
    return (
        <div className='table-content'>
            <Container fluid className='thead p-0 pt-2 pb-2'>
                <Row className='m-0'>
                    <Col className='p-0 m-0 border-right' style={{width:'40px', flex:'0 0 40px'}}>

                    </Col>
                    <Col className='p-0 m-0 border-right' style={{width:'calc(100% - 40px)', flex:'0 0 calc(100% - 40px)'}}>
                        <Container fluid className='p-0 m-0'>
                            <Row className='m-0'>
                                <Col xs={1} className='border-right'>ID</Col>
                                <Col xs={2} className='border-right'>Título</Col>
                                <Col xs={userLogin.perfil !== 'Jurado' ? 3 : 6} className='border-right'>Resumen</Col>
                                {
                                userLogin.perfil !== 'Jurado'
                                ?
                                <Col xs={1} className='border-right'>
                                    <div data-tooltip-id='puntos' data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                        <div style={{"width":"200px"}}>Los puntos son promediados por la puntuacion de las evaluaciones, divido la candidad de jurados para obtener el puntaje total permitido</div>
                                    )}>Puntos</div>
                                    <Tooltip id='puntos' />
                                </Col>
                                :
                                <></>
                                }
                                {userLogin.perfil !== 'Jurado' ? <Col xs={2} className='border-right'>Jurado(s)</Col> : <></>}
                                <Col xs={2} className='border-right'>Estado</Col>
                                <Col xs={1} className='border-right'>Acciones</Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='tbody p-0'>
                {contentInvestigaciones.map((item) => {
                    const jurado = item.jurados !== undefined ? item.jurados.find(( jurado ) => {
                        return jurado.id === userLogin.id 
                    }) : undefined

                    const evaluado = jurado !== undefined &&  item.evaluaciones !== undefined ? item.evaluaciones.find(( evaluacion ) => {
                        return evaluacion.id === jurado.id && evaluacion.tipoEvaluacion === jurado.tipoEvaluacion 
                    }) : undefined
                    

                    console.log("item.evaluaciones", item.evaluaciones)

                    return (
                        <Row key={item.id} className='m-0 p-0 pt-2 pb-2'>
                            <Col className='p-0 m-0 d-flex justify-content-center align-items-center' style={{width:'40px', flex:'0 0 40px'}}>
                                {userLogin.perfil === 'Administrador' ?
                                    <input type='checkbox' className='form-check-input' name={item.id} value={item.id} checked={isChecked[item.id] === undefined || isChecked[item.id] === false ? false : true} onChange={handleOnChange}/>
                                :
                                ''}
                            </Col>
                            <Col className='p-0 m-0' style={{width:'calc(100% - 40px)', flex:'0 0 calc(100% - 40px)'}}>
                                <Container fluid className='p-0 m-0'>
                                    <Row className='m-0'>
                                        <Col xs={1}><p className='cortar-texto id'>{item.id}</p></Col>
                                        <Col xs={2}><p className='cortar-texto titulo'>{item.titulo}</p></Col>
                                        <Col xs={userLogin.perfil !== 'Jurado' ? 3 : 6}><p className='cortar-texto resumen'>{item.resumen.replace( /(<([^>]+)>)/ig, '')}</p></Col>
                                        {userLogin.perfil !== 'Jurado' ? <Col xs={1}> <p className='cortar-texto total-puntos'>{item.totalPuntuacion}</p></Col> : <></>}
                                        {
                                        userLogin.perfil !== 'Jurado'
                                        ?
                                        <Col xs={2}>
                                            {
                                                item.jurados.length === 0 
                                                ? '' 
                                                : 
                                                <>
                                                    <div data-tooltip-id={item.id}data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                        item.jurados.map((item, index)=>{
                                                            return <><span key={item.id + index}>{item.nombre} {item.apellido} ({item.tipoEvaluacion})</span><br /></>
                                                        })
                                                    )}>{item.jurados.length === 1 ? item.jurados.length + " jurado" : item.jurados.length + " jurados"}</div>
                                                    <Tooltip id={item.id} />
                                                </>
                                            }
                                        </Col>
                                        :
                                        <></>
                                        }

                                        {
                                        userLogin.perfil === 'Administrador'
                                        ?
                                        <Col xs={2}><p className='estado'><span className={item.status[0].name.toLowerCase()}>{item.status[0].name}</span></p></Col>
                                        :
                                        evaluado !== undefined ? <Col xs={2}><p className='estado'><span className={item.status[0].name.toLowerCase()}>{item.status[0].name}</span></p></Col> : <Col xs={2}><p className='estado'><span className='Inscrito'>Inscrito</span></p></Col>
                                        }
                                        <Col xs={1} className='justify-content-end d-flex align-content-center'><Button onClick={(e) => {e.preventDefault();handleViewInvestigation(item.id)}} className='btn-custom'>Detalles</Button></Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    )
                })}
            </Container>
        </div>
    )
}
