import { collection, getDocs } from "firebase/firestore"
import { dbApp } from "../configFirebase"

function getHtmlText(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html'),
    text = doc.body.textContent || '';
    // Limpiamos los espacios
    text = text.trim().replace(/\s{2,}/g, ' ').replace(/(\r\n|\n|\r)/gm, "")
    return text;
}

export const exportarData = async (type) => {
    let collectionRef = ''
    if(type === 'investigaciones'){
        collectionRef = collection(dbApp, 'trabajos-libres/abstracts/abstracts')
        let response = await getDocs(collectionRef)
        if(response.docs.length !== 0){
            let data = response.docs.map( (doc) => {
                let data = {...doc.data()}
                let modalidad = data.modalidad !==  undefined ? data.modalidad[0].name : 'SIN DEFINIR'
                return ( {id: doc.id, ...data, date:data.date.toDate().toLocaleDateString('es-CO', { year:"numeric", month:"numeric", day:"numeric"}) , status:data.status[0].name, resumen:data.resumen, titulo:data.titulo.replace( /(<([^>]+)>)/ig, ''), modalidad: modalidad})   
            })
            //organizar data
            let dataFinal = []
            data.forEach((investigacion)=>{

                let dataJson = {
                    id:investigacion.id,
                    categoria:investigacion.categoria,
                    fecha: investigacion.date,
                    titulo: investigacion.titulo,
                    resumen: getHtmlText(investigacion.resumen),
                    relevancia:investigacion.relevancia,
                    palabras_clave: investigacion.palabrasClaves,
                    estado:investigacion.status,
                    publicacion:investigacion.publicacion,
                    modalidad:investigacion.modalidad,
                }

                if(investigacion.jurados !== undefined){
                    investigacion.jurados.forEach((jurado,index)=>{
                        let title = "jurado_"+(index+1)
                        let value = jurado.nombre+" "+jurado.apellido+" ("+jurado.tipoEvaluacion+")"
                        dataJson[title] = value
                    })
                }
                
                if(investigacion.evaluaciones !== undefined){
                    investigacion.evaluaciones.forEach((evaluacion, index)=>{
                        let title = "evaluacion_"+evaluacion.nombre+"_"+evaluacion.apellido+"_("+evaluacion.tipoEvaluacion+")"
                        let value = evaluacion.total
                        dataJson[title] = value
                    })



                    let totalPuntosPrevios = 0
                    let totalPuntosEvento = 0

                    let dataPreviaEvento = investigacion.evaluaciones.filter((item) => item.tipoEvaluacion === "metodologico" || item.tipoEvaluacion === "clinico")
                    dataPreviaEvento.forEach((item)=>{
                        totalPuntosPrevios = totalPuntosPrevios + parseInt(item.total)
                    })

                    let dataPreviaDurante = investigacion.evaluaciones.filter((item)=> item.tipoEvaluacion === "evento")
                    dataPreviaDurante.forEach((item)=>{
                        totalPuntosEvento = totalPuntosEvento + parseInt(item.total)
                    })



                    totalPuntosPrevios  = totalPuntosPrevios / 2

                    dataJson["puntaje_fase_previa"] = totalPuntosPrevios
                    dataJson["puntaje_fase_evento"] = totalPuntosEvento

                    dataJson["punta_final"] = (( totalPuntosPrevios * 0.70 ) + ( totalPuntosEvento * 0.30 )).toFixed(2)
                    
                }
                dataFinal.push(dataJson)
            })
            console.log(dataFinal)
            return dataFinal
        }
        else {
            return null
        }
    }

    
}