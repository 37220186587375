import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { ContextAppAdministracion } from '../../../../../context/ContextAppAdministracion'
import { FormulariosEvaluacionesEvento } from './Formularios/FormulariosEvaluacionesEvento'

export const EvaluacionesEvento = ({contenidoInvestigacion}) => {
    const {cambioDashboard, userLogin, updateEvaluacionInvestigacion} = useContext(ContextAppAdministracion)

    const handleDevolverse = () => {
      cambioDashboard('detallesInvestigacion',{tipo:"investigacion", id:contenidoInvestigacion.id})
    }
  
  
  
    const updateEvalucionMetodologico = (userLogin, itemsEvaluados, puntos) => {
        const evaluacion = []
        const jurado = contenidoInvestigacion.jurados.find((item)=> item.id === userLogin.id && item.tipoEvaluacion === "evento")
        const dataEvaluacion = {...jurado, evaluacion:{itemsEvaluados}, total:puntos}
        if(contenidoInvestigacion.evaluaciones !== undefined){
                
            if(contenidoInvestigacion.evaluaciones.some((item) => item.id === dataEvaluacion.id && item.tipoEvaluacion === "evento")){
            const data = contenidoInvestigacion.evaluaciones.filter((item) => item.id !== dataEvaluacion.id && item.tipoEvaluacion !== "evento")
            evaluacion.push(...data)
            }
            else{
            evaluacion.push(...contenidoInvestigacion.evaluaciones)
            }
        }
        evaluacion.push(dataEvaluacion)
      
  
  
        //-------- Puntos de las evaluaciones previas al evento
        let totalPuntosMetodologico = 0
        let totalPuntosClinito = 0
        let totalPuntosEvento = 0

        const totalJuradosMetodologico = contenidoInvestigacion.jurados.filter((item)=> item.tipoEvaluacion === "metodologico" )
        const totalJuradosClinico = contenidoInvestigacion.jurados.filter((item)=> item.tipoEvaluacion === "clinico" )
        
        const evaluacionesMetodologicas = evaluacion.filter((item) => item.tipoEvaluacion === "metodologico" )
        const evaluacionesClinitas = evaluacion.filter((item)=> item.tipoEvaluacion === "clinico" )
        const evaluacionesEvento = evaluacion.filter((item)=> item.tipoEvaluacion === "evento" )

        evaluacionesMetodologicas.forEach(element => {
            totalPuntosMetodologico = totalPuntosMetodologico + element.total
        });
        evaluacionesClinitas.forEach(element => {
            totalPuntosClinito = totalPuntosClinito + element.total
        });
        evaluacionesEvento.forEach(element => {
            totalPuntosEvento = totalPuntosEvento + element.total
        });
  
        //-------- Puntos de las evaluaciones post al evento
    
        const totalPuntos = ( parseInt(totalPuntosMetodologico) / parseInt(totalJuradosMetodologico.length) ) + ( parseInt(totalPuntosClinito) / parseInt(totalJuradosClinico.length) ) + parseInt(totalPuntosEvento)
    
        updateEvaluacionInvestigacion(evaluacion, totalPuntos, contenidoInvestigacion.id).then(()=>{
            cambioDashboard('investigaciones')
        })
    }
  
  
    return (
      <Container fluid className='h-100 p-4 detalles-investigacion'>
              <Row>
                  <Col xs={12}>
                      <Container fluid className='m-0 p-0'>
                          <Row className='m-0 p-0'>
                              <Col xs={12} className='p-0 d-flex align-items-center align-content-center'>
                                <Button onClick={handleDevolverse} className='btn-custom' style={{'marginRight':'10px'}}><p><FontAwesomeIcon icon={faArrowLeft}/>Atras</p></Button>
                                <h1 className='m-0'>Evaluación Evento</h1>
                              </Col>
                          </Row>
                      </Container>
                      <FormulariosEvaluacionesEvento contenidoInvestigacion={contenidoInvestigacion} userLogin={userLogin} updateEvalucionMetodologico={updateEvalucionMetodologico}/>
                    </Col>
              </Row>
          </Container>
    )
}
