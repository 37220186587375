import React, { useState, useEffect } from 'react'

import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { categoriasTrabajosLibres } from '../../../../../firebase/helpers/categoriasTrabajosLibres'

export const FiltersInvestigaciones = ({listInvestigaciones, contentInvestigaciones, setContentInvestigaciones}) => {
  const [valueForm, setValueForm] = useState({})
  const [listCategoryTrabajosLibres, setListCategoryTrabajosLibres] = useState([])

  useEffect(() => {
    categoriasTrabajosLibres().then((response)=>{
      setListCategoryTrabajosLibres([...response])
    })
  }, [])

  const changeField = (field, value) => {
    setValueForm({
      ...valueForm,
      [field]:value
    })
  }
  const changeFilterCategory = (field, value) => {
    let response = '';
    if(value === ''){
      response = listInvestigaciones
    }
    else{
      response = listInvestigaciones.filter((item) => {
        return item["categoria"].toLowerCase() === value.toLowerCase() 
      })
    }
    setContentInvestigaciones(response)
  }
  const handleFilterInvestigaciones = (e) => {
      e.preventDefault()
      let response = listInvestigaciones.filter((item) => {
        return item["id"].toLowerCase().indexOf(valueForm['filterBuscar'].toLowerCase()) !== -1 || item["titulo"].toLowerCase().indexOf(valueForm['filterBuscar'].toLowerCase()) !== -1 || item["resumen"].toLowerCase().indexOf(valueForm['filterBuscar'].toLowerCase()) !== -1 || item["categoria"].toLowerCase().indexOf(valueForm['filterBuscar'].toLowerCase()) !== -1 
      })
      setContentInvestigaciones(response)
  }
  if(listInvestigaciones === null){
      return (<></>)
  }


  return (
    <Container fluid className='p-0 m-0 mt-4 mb-4'>
      <Row className='m-0 p-0'>
        <Col xs={3} className='p-0 d-flex align-content-center align-items-center'>
        <p className='items-total-investigaciones m-0'>Total de investigaciones {listInvestigaciones.length} de {contentInvestigaciones.length}</p>
        </Col>
        <Col xs={9} className='p-0 d-flex justify-content-end'>
          <Form onSubmit={handleFilterInvestigaciones} className='d-flex'>

            <Form.Select style={{width:"400px"}}size="sm" name='categoria' placeholder='Filtro por categorÍa' required onChange={(e) => {changeFilterCategory(e.target.name, e.target.value);changeField(e.target.name, e.target.value)} } value={valueForm.categoria === undefined ? "" : valueForm.categoria} >
                <option value=''>SELECCIONE UN CATEGORÍA</option>
                {listCategoryTrabajosLibres.length !== 0
                ?
                listCategoryTrabajosLibres.map((item) => {
                    return <option key={item.id} value={item.name}>{item.name}</option>
                    })
                :''}
            </Form.Select>
            <Form.Control type='text' placeholder='Buscar...' name='filterBuscar' className='inputSearch' onChange={(e) => {changeField(e.target.name, e.target.value)} } value={valueForm.filterBuscar === undefined ? "" : valueForm.filterBuscar} />
            <Button className='btnFilterSearch btn-custom ml-4' onClick={handleFilterInvestigaciones}><FontAwesomeIcon icon={faSearch} /></Button>
          </Form>
          
        </Col>
      </Row>
    </Container>
  )
}
