import React, { useContext, useState} from 'react'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { ContextAppAdministracion } from '../../../../context/ContextAppAdministracion'

export const DasnboardEvaluaciones = ({contenidoInvestigacion}) => {
    const {userLogin, cambioDashboard} = useContext(ContextAppAdministracion)
    const [evaluacion, setEvaluacion] = useState([])
    

    useEffect(()=>{
        const data = contenidoInvestigacion.jurados.filter((item) => item.id === userLogin.id)
        console.log("data", data)
        if(contenidoInvestigacion.evaluaciones === undefined){
            const evaluadores = []
            data.forEach(evaluador => {
                evaluadores.push({...evaluador, button:"Evaluación"})
            })
            setEvaluacion(evaluadores)
        }
        else{
            const evaluadores = []
            data.forEach(evaluador => {
                let result = contenidoInvestigacion.evaluaciones.find((item)=> item.id === evaluador.id && item.tipoEvaluacion === evaluador.tipoEvaluacion)
                if(result === undefined){
                    evaluadores.push({...evaluador, button:"Evaluación"})
                }
                else{
                    evaluadores.push({...evaluador, button:"Editar"})
                }
            });
            setEvaluacion(evaluadores)
        }
    },[])
    const handleEvaluacion = (tipo) => {
        let data = 'evaluacion-'+tipo.toLowerCase()
        cambioDashboard(data, null, contenidoInvestigacion)
    }

    
    //si no tiene jurados asignados
    if(contenidoInvestigacion.jurados.length === 0  || evaluacion.length === 0){
        return ( <></> )
    }
    return (
        <div className='content-evaluacion d-flex flex-column'>
            {evaluacion.map((item, index)=> {
                if(item.tipoEvaluacion === "metodologico"){
                    return <Button key={item.id+index} onClick={(e) => {e.preventDefault(); handleEvaluacion('metodologico')}} className='btn-custom mb-2 w-100' >{item.button} Metodológica</Button>
                }
                if(item.tipoEvaluacion === "clinico" ){
                    return <Button key={item.id+index} onClick={(e) => {e.preventDefault(); handleEvaluacion('clinico')}} className='btn-custom mb-2 w-100' >{item.button} Clinico</Button>
                }
                if(item.tipoEvaluacion === "evento" ){
                    return <Button key={item.id+index} onClick={(e) => {e.preventDefault(); handleEvaluacion('evento')}} className='btn-custom mb-2 w-100' >{item.button} Evento</Button>
                }
                return <></>
            })

            }
        </div>
    )
}
